import React, { useState, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import CameraStackContext from '../context/CameraStackContext';
import LocationContext from '../context/LocationContext';

const STACK_LIMIT = 5;

const CameraStackLayout = () => {
  const { locationId } = useContext(LocationContext);

  const navigate = useNavigate();

  const [stack, setStack] = useState([]);

  useEffect(() => {
    if (locationId) {
      let lastVisited = localStorage.getItem('last_visited');
      lastVisited = JSON.parse(lastVisited) || {};
      if (!lastVisited || Array.isArray(lastVisited)) {
        lastVisited = {};
        localStorage.setItem('last_visited', JSON.stringify(lastVisited));
      }

      const savedStack = lastVisited[locationId] || [];

      setStack(savedStack);
    }
  }, [locationId, navigate]);

  function addToStack(id, name) {
    let copy = [...stack];
    const existingIndex = stack.findIndex(d => d.id === id);
    if (existingIndex > -1) {
      copy = [...stack];
    } else {
      copy = [{ id, name }, ...stack];
    }

    copy = copy.slice(0, STACK_LIMIT);

    let lastVisited = localStorage.getItem('last_visited')
    lastVisited = JSON.parse(lastVisited) || {};
    lastVisited = {
      ...lastVisited,
      [locationId]: copy
    };

    localStorage.setItem('last_visited', JSON.stringify(lastVisited));
    setStack(copy);
  }

  function removeFromStack(id) {
    let copy = [...stack];
    const existingIndex = stack.findIndex(d => d.id === id);
    if (existingIndex > -1) {
      copy = [
        ...stack.slice(0, existingIndex),
        ...stack.slice(existingIndex + 1)
      ];
    }

    let lastVisited = localStorage.getItem('last_visited')
    lastVisited = JSON.parse(lastVisited) || {};
    lastVisited = {
      ...lastVisited,
      [locationId]: copy
    };

    localStorage.setItem('last_visited', JSON.stringify(lastVisited));
    setStack(copy);

    return {
      index: existingIndex,
      stack: copy
    }
  }

  function closeTab(id) {
    const { index, stack } = removeFromStack(id);

    if (stack.length) {
      const nextIndex = index > stack.length - 1
        ? stack.length - 1
        : index;

      const nextTab = stack[nextIndex];
      navigate(`/producers/herd-sense?locationId=${locationId}&deviceId=${nextTab.id}`);
    } else {
      navigate(`/producers/dashboard?locationId=${locationId}`);
    }
  }

  function closeAllTabs() {
    localStorage.setItem('last_visited', JSON.stringify({}));
    setStack([]);
    navigate(`/producers/dashboard`);
  }

  return (
    <CameraStackContext.Provider
      value={{
        stack,
        addToStack,
        removeFromStack,
        closeTab,
        closeAllTabs
      }}
    >
      <Outlet />
    </CameraStackContext.Provider>
  );
};

export default CameraStackLayout;
